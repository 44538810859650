import Image from "next/image";
import Animate from "../Common/AnimateOnScroll";

const Process = () => {
  return (
    <div className="flex-col items-center hidden max-w-screen-xl px-8 py-10 mx-auto mt-10 space-y-4 xl:flex md:px-16">
      <div className="flex flex-col items-center space-y-2">
        <Animate.ScaleIn>
          <div className="flex items-center space-x-2">
            <p
              className={`font-bold text-2xl md:text-5xl text-primary fontNew`}
            >
              Our Development
            </p>
            <p
              className={`font-bold text-2xl md:text-5xl text-secondary fontNew`}
            >
              Process
            </p>
          </div>
        </Animate.ScaleIn>
        <Animate.ScaleIn>
          <span className="flex items-center justify-center max-w-xl mt-1 text-base font-normal text-center">
            Our development process is a systematic approach that ensures
            efficient and successful software development projects. Here is an
            overview of our development process:
          </span>
        </Animate.ScaleIn>
      </div>
      <Animate.ScaleIn>
        <div className="relative flex flex-col space-y-28 w-[1300px]">
          <Image
            src={"/images/technologies_circle.svg"}
            width={200}
            height={200}
            className="absolute left-10 top-32 -z-20 md:w-[513px] md:h-[513px]"
            alt="our process"
          />
          <Image
            src={"/images/arrow-1.svg"}
            width={250}
            height={245}
            alt="zysoftec development process"
            className="absolute top-[12%] left-12 -z-20"
          />
          <Image
            src={"/images/arrow-2.svg"}
            width={80}
            height={144}
            alt="zysoftec development process"
            className="absolute top-[5%] left-[35%]"
          />
          <Image
            src={"/images/arrow-3.svg"}
            width={204}
            height={162}
            alt="zysoftec development process"
            className="absolute top-10 right-20"
          />
          <div className="flex flex-col items-start justify-start md:flex-row md:space-x-20">
            <div className="flex items-start space-x-2">
              <div className="bg-[#FA7070] flex items-center justify-center rounded-full w-[132px] h-[132px]">
                <Image
                  src={"/images/pro-planning.svg"}
                  width={62}
                  height={70}
                  alt={"project planning"}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <p className={`fontNew text-xl font-semibold text-primary`}>
                  Project planning
                </p>
                <span className="text-[.93rem] font-normal max-w-xs fontNew">
                  We collaborate closely with our clients to understand their
                  requirements,&nbsp;
                  <a
                    className="font-semibold"
                    target="_blank"
                    href="/services/web/landing-page"
                  >
                    goals, and objectives
                  </a>
                  , ensuring a comprehensive plan is in place before starting
                  development.
                </span>
              </div>
            </div>
            <div className="flex items-start space-x-2">
              <div className="bg-[#FA7070] flex items-center justify-center rounded-full w-[132px] h-[132px]">
                <Image
                  src={"/images/dev.svg"}
                  width={62}
                  height={70}
                  alt={"software development"}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <p className={`fontNew text-xl font-semibold text-primary`}>
                  Development
                </p>
                <span className="max-w-xs text-base font-normal text-justify fontNew">
                  Our skilled developers bring the designs to life, writing
                  clean and&nbsp;
                  <a
                    className="font-semibold"
                    target="_blank"
                    href="/services/app/backend-app-development"
                  >
                    efficient code
                  </a>
                  &nbsp; while following industry standards and best practices.
                </span>
              </div>
            </div>
          </div>
          {/* Second row */}
          <div className="flex flex-col items-start justify-end md:flex-row md:space-x-20">
            <div className="flex items-start space-x-2">
              <div className="bg-primary flex items-center justify-center rounded-full w-[132px] h-[132px]">
                <Image
                  src={"/images/design.svg"}
                  width={62}
                  height={70}
                  alt={"designing"}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <p className={`fontNew text-xl font-semibold text-primary`}>
                  Desinging
                </p>
                <span className="max-w-xs text-base font-normal text-justify fontNew">
                  Our experienced team of designers creates intuitive and&nbsp;
                  <a
                    className="font-semibold"
                    target="_blank"
                    href="/services/design/mobile-ui-design"
                  >
                    user-friendly interfaces
                  </a>
                  , ensuring a seamless user experience while adhering to the
                  client's brand guidelines.
                </span>
              </div>
            </div>
            <div className="flex items-start space-x-2">
              <div className="bg-primary flex items-center justify-center rounded-full w-[132px] h-[132px]">
                <Image
                  src={"/images/testing.svg"}
                  width={62}
                  height={70}
                  alt={"testig and deployment"}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <p className={`fontNew text-xl font-semibold text-primary`}>
                  Testing and deployment
                </p>
                <span className="max-w-xs text-base font-normal text-justify fontNew">
                  We rigorously test and deploy the developed software, ensuring
                  it meets the highest quality standards and is ready to be used
                  by our clients and their end-users.
                </span>
              </div>
            </div>
          </div>
        </div>
      </Animate.ScaleIn>
    </div>
  );
};

export default Process;
